.vloader{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.record-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.record-div .screen {
  position: relative;
  height: 100vh;
  overflow: scroll;
  width: 100%;
  max-width: 800px;
  scroll-snap-type: y mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.record-div .screen::-webkit-scrollbar {
  display: none;
}

.record-div .idle-screen {
  position: relative;
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
}

.record-div .idle-screen .idle-video {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.record-div .idle-screen .close-idle{
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  cursor: pointer;
}

.record-div .idle-screen .close-idle .close-icon{
  font-size: 40px;
  color: #fff;
}

.record-div .idle-screen .record-button{
  position: absolute;
  bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 2;
  cursor: pointer;
}

.record-div .recording-screen {
  position: relative;
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
}

.record-div .recording-screen .recording-video {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.record-div .recording-screen .close-recording{
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  cursor: pointer;
}

.record-div .recording-screen .close-recording .close-icon{
  font-size: 40px;
  color: #fff;
}

.record-div .recording-screen .pause-button{
  position: absolute;
  bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 2;
  cursor: pointer;
}

.record-div .stopped-screen{
  position: relative;
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
}

.record-div .stopped-screen .stopped-video {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.record-div .stopped-screen .close-stopped{
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  cursor: pointer;
}

.record-div .stopped-screen .close-stopped .back-button{
  background: linear-gradient(92.52deg, #58338f 3.21%, #852993 100%);
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-transform: none;
  color: #ffffff;
}

.record-div .stopped-screen .upload-button{
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  background: linear-gradient(92.52deg, #58338f 3.21%, #852993 100%);
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-transform: none;
  color: #ffffff;
}

.record-div .stopped-screen .play {
  position: absolute;
  top: 40%;
  left: 45%;
  color: white;
  cursor: pointer;
}

.record-div .stopped-screen .video-slider {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}