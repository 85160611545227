@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

html {
  scroll-snap-type: y mandatory;
}

body {
  background-color: #131921;
  color: #fff;
}

.loading{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.video-div{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-div .home-videos{
  position: relative;
  height: 80vh;
  overflow: scroll;
  width: 100%;
  max-width: 800px;
  scroll-snap-type: y mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.home-div .home-videos::-webkit-scrollbar {
  display: none;
}