.burger-div .burger-bar {
  background-color: #131921;
}

.burger-div .heading {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.burger-div .heading .heading-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.burger-div .heading .subheading-text {
  margin-top: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  width: 410px;
  height: 70px;
}

.burger-div .login {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.burger-div .login .login-logo {
  font-size: 40px;
  margin-right: 20px;
}

.burger-div .login .login-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
