.loading{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.upload-div .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.upload-div .header .cancel {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
}

.upload-div .header .upload-text {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.upload-div .header .upload-button {
  background: linear-gradient(92.52deg, #58338f 3.21%, #852993 100%);
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-transform: none;
  color: #ffffff;
}

.upload-div .category {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.upload-div .category .small-text {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
}

.upload-div .category .select {
  border: none;
  background-color: #131921;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}

.upload-div .category .select:focus {
  outline: none;
}

.upload-div .product {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.upload-div .product .small-text {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
}

.upload-div .product .select {
  border: none;
  background-color: #131921;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}

.upload-div .product .select:focus {
  outline: none;
}

.upload-div .product .select .commisson{
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 11px;
  color: rgba(255, 255, 255, 0.5);
}

.upload-div .sub-category {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.upload-div .sub-category .small-text {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
}

.upload-div .sub-category .select {
  border: none;
  background-color: #131921;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}

.upload-div .sub-category .select:focus {
  outline: none;
}

.upload-div .type {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.upload-div .type .small-text {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
}

.upload-div .type .select {
  border: none;
  background-color: #131921;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}

.upload-div .type .select:focus {
  outline: none;
}


.upload-div .desc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.upload-div .desc .small-text {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
}

.upload-div .desc .textarea {
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  background-color: #131921;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  padding: 4px;
}

.upload-div .desc .textarea:focus {
  outline: none;
}

.upload-div .error{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 5px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 17px;
  color: #ff0000;
}
