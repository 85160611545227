@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.video {
  position: relative;
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
}

.video .video-player {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.vjs-control-bar {
  display: none;
}

.vjs-tech {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
  visibility: hidden;
}

.vjs-default-skin.vjs-paused .vjs-big-play-button {
  display: none;
}

.vjs-default-skin.vjs-paused .vjs-control-bar {
  display: block;
}

.video .video-slider {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.video .share {
  position: absolute;
  right: 5px;
  bottom: 210px;
  z-index: 2;
  font-size: 40px;
  cursor: pointer;
  color: #fff;
}

.video .product {
  position: absolute;
  bottom: 70px;
  left: 15px;
  z-index: 1;
}

.video .button {
  position: absolute;
  bottom: 80px;
  right: 15px;
  z-index: 1;
}

.video .button .sell-button {
  background-color: #f7a01e;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: none;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 120px;
  border-radius: 4px;
}

.video .product .name {
  width: 220px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.045em;
  text-transform: none;
  color: #fff;
}

.video .mute {
  position: absolute;
  right: 15px;
  bottom: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  cursor: pointer;
}

.video .play {
  position: absolute;
  top: 40%;
  left: 45%;
  color: white;
  cursor: pointer;
}

.share-button{
  margin-right: 25px;
  margin-bottom: 30px;
}

.share-button .text{
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}