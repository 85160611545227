@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.loading{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.profile-div .profile-back {
  margin-top: 20px;
  margin-left: 20px;
  cursor: pointer;
}

.profile-div .profile-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.profile-div .profile-logo img {
  border-radius: 50%;
  margin-bottom: 10px;
}

.profile-div .profile-logo .user-name {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}

.profile-div .user-info {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 40px;
}

.profile-div .user-info .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-div .user-info .info .text-1 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}

.profile-div .user-info .info .text-2 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.profile-div .profile-buttons {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 40px;
}

.profile-div .profile-buttons .p-earn {
  background: linear-gradient(92.52deg, #58338f 3.21%, #852993 100%);
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  border-radius: 4px;
}

.profile-div .profile-buttons .p-edit {
  border: 1px solid #862993;
  background-color: #131921;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

.profile-div .info-text {
  margin-top: 10px;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #f7a01e;
}

.profile-div .profile-list {
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 40px;
}

.profile-div .profile-list .list-item{
    cursor: pointer;
}

.profile-div .p-videos{
  margin-top: 40px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.profile-div .p-videos .video-item{
  position: relative;
}

.profile-div .p-videos .video-item .small-video{
  width: 200px;
  height: 300px;
  object-fit: cover;
}

.profile-div .p-videos .video-item .share-video{
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.profile-div .p-videos .video-item .share-video .share-icon{
  font-size: 30px;
}


@media screen and (max-width: 1024px) {
  .profile-div .info-text {
    width: 54%;
  }
}

@media screen and (max-width: 768px) {
  .profile-div .info-text {
    width: 60%;
  }
}

@media screen and (max-width: 576px) {
  .profile-div .user-info {
    width: 100%;
  }
  .profile-div .profile-buttons {
    width: 90%;
  }
  .profile-div .info-text {
    width: 90%;
  }
  .profile-div .profile-list {
    width: 100%;
  }
  .profile-div .p-videos{
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
  .profile-div .user-info {
    margin-top: 0px;
    display: block;
  }
  .profile-div .user-info .info{
      margin-top: 30px;
  }
  .profile-div .profile-buttons {
    width: 90%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .profile-div .profile-buttons .p-earn {
    margin-bottom: 20px;
  }
  .profile-div .info-text {
    width: 90%;
  }
  .profile-div .profile-list {
    width: 100%;
  }
}
