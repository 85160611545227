@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");


.nav-div .navbar {
  background-color: #131921;
}

.nav-div .sell-button {
  background-color: #f7a01e;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-transform: none;
}

.nav-div .sell-button:hover {
  background-color: #f3a125;
  color: #fff;
}

.nav-div .filters {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 20px;
}

@media (max-width: 480px) {

  .nav-div .filters {
    margin-left: 0;
    display: block;
  }
}

.nav-div .filters .filter {
  border: none;
  background-color: #131921;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-right: 20px;
}
